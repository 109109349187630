/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_appsync_graphqlEndpoint": "https://mjcqsvalejfodkorgfuomuwmh4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-atbisrb245dxddnbubnx2fzthy",
    "aws_cognito_identity_pool_id": "eu-west-2:d05841bc-bdef-45cc-acca-519b7ad573ad",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_IRk21L6Rg",
    "aws_user_pools_web_client_id": "68drvic6agf2v68ndv35e4a3vn",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "predictions": {
        "convert": {
            "speechGenerator": {
                "region": "eu-west-2",
                "proxy": false,
                "defaults": {
                    "VoiceId": "Amy",
                    "LanguageCode": "en-GB"
                }
            }
        }
    }
};


export default awsmobile;
